import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Spinner, SpinnerContainer } from '@/components/common/Spinner.tsx'
import { Switch } from '@/components/common/Switch.tsx'
import {
  useDeleteFeatureFlag,
  useFeatureFlags,
  useUpdateFeatureFlag,
} from '@/hooks/admin/useFeatureFlags.ts'
import i18n from '@/i18n'
import { ActionButtons } from '@/screens/admin/UserManagement/ActionButtons.tsx'
import { ContentTable } from '@/screens/admin/UserManagement/ContentTable.tsx'
import { DeleteModal } from '@/screens/admin/UserManagement/DeleteModal.tsx'
import { EditModal } from '@/screens/admin/UserManagement/EditModal.tsx'
import { useFeatureFlagFormFields } from '@/screens/admin/UserManagement/featureFlags/useFeatureFlagFormFields.ts'
import { FeatureFlagFromAPI } from '@/types/featureFlag.ts'

export const FeatureFlagsTable = ({
  onRowClick,
}: {
  onRowClick: (item: FeatureFlagFromAPI) => void
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'userManagement.featureFlags',
  })
  const { featureFlags, isLoading } = useFeatureFlags()

  const columns = useMemo<ColumnDef<FeatureFlagFromAPI>[]>(
    () => [
      {
        accessorKey: 'key',
        header: t('columns.key'),
      },
      {
        accessorKey: 'title',
        header: t('columns.title'),
      },
      {
        accessorKey: 'description',
        header: t('columns.description'),
      },
      {
        accessorFn: (row) => row,
        cell: ({ getValue }) => {
          const featureFlag = getValue() as FeatureFlagFromAPI
          if (!featureFlag) {
            return null
          }
          return (
            <Switch
              checked={featureFlag.enabled}
              onChange={(checked, e) => {
                e?.stopPropagation()
                updateFeatureFlag({
                  featureFlag: {
                    ...featureFlag,
                    enabled: checked,
                  },
                  key: featureFlag.key,
                })
              }}
            />
          )
        },
        header: t('columns.enabled'),
      },
      {
        accessorFn: (row) => row,
        cell: ({ getValue }) => {
          const featureFlag = getValue() as FeatureFlagFromAPI

          return (
            <ActionButtons
              onDelete={(e) => {
                setFeatureFlagToDelete(featureFlag.key)
                e.stopPropagation()
              }}
              onEdit={(e) => {
                setFeatureFlagToEdit(featureFlag)
                e.stopPropagation()
              }}
            />
          )
        },
        enableColumnFilter: false,
        header: t('columns.actions'),
        id: 'actions',
      },
    ],
    [i18n.language],
  )
  const table = useReactTable<FeatureFlagFromAPI>({
    columns,
    data: featureFlags,
    enableColumnFilters: false,
    getCoreRowModel: getCoreRowModel(),
  })

  const { editFeatureFlagFormFields } = useFeatureFlagFormFields()
  const { isError: isErrorUpdating, updateFeatureFlag } = useUpdateFeatureFlag({
    onSuccess: () => {
      setFeatureFlagToEdit(null)
    },
  })
  const { deleteFeatureFlag, isError: isErrorDeleting } = useDeleteFeatureFlag({
    onSuccess: () => {
      setFeatureFlagToDelete(null)
    },
  })
  const [featureFlagToDelete, setFeatureFlagToDelete] = useState<string | null>(
    null,
  )
  const [featureFlagToEdit, setFeatureFlagToEdit] =
    useState<FeatureFlagFromAPI | null>(null)

  if (isLoading) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    )
  }
  return (
    <>
      <ContentTable table={table} onRowClick={onRowClick} />
      {featureFlagToEdit && (
        <EditModal
          closeModal={() => setFeatureFlagToEdit(null)}
          data={{
            ...featureFlagToEdit,
            description: featureFlagToEdit.description ?? '',
            value: featureFlagToEdit.value?.join(', ') ?? '',
          }}
          errorMessage={t('editModal.error')}
          formFields={editFeatureFlagFormFields}
          isError={isErrorUpdating}
          onSave={(data) => {
            updateFeatureFlag({
              featureFlag: {
                ...data,
                enabled: featureFlagToEdit.enabled,
                value: data.value?.split(',').map((q) => q.trim()),
              },
              key: featureFlagToEdit.key,
            })
          }}
          title={t('editModal.title')}
        />
      )}
      {featureFlagToDelete && (
        <DeleteModal
          caption={t('deleteModal.caption', {
            title: featureFlags?.find((q) => q.key === featureFlagToDelete)
              ?.title,
          })}
          closeModal={() => {
            setFeatureFlagToDelete(null)
          }}
          errorMessage={t('deleteModal.error')}
          isError={isErrorDeleting}
          onDelete={() => deleteFeatureFlag({ key: featureFlagToDelete })}
          title={t('deleteModal.title')}
        />
      )}
    </>
  )
}
